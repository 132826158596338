import { render, staticRenderFns } from "./SingleOversizeItemFee.vue?vue&type=template&id=63bfc979&scoped=true"
import script from "./SingleOversizeItemFee.vue?vue&type=script&lang=js"
export * from "./SingleOversizeItemFee.vue?vue&type=script&lang=js"
import style0 from "./SingleOversizeItemFee.vue?vue&type=style&index=0&id=63bfc979&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63bfc979",
  null
  
)

export default component.exports