<template>
  <div class="row m-1">
    <div class="col-xl-8">
      <div class="card card-psh border">
        <div class="bg-light-primary rounded-top py-2 px-1">
          <div class="psh-header mb-0 d-flex align-items-center">
            <div class="mr-1 border-0">
              <p-icon name="bi-gear-fill" size="48px" color="primary" />
            </div>
            <div class="d-flex flex-column">
              <h4 class="card-title mb-25">
                Oversize Item Fee
              </h4>
              <p class="card-text mb-0">
                Customize your fees based on product size tiers.
              </p>
            </div>
          </div>
        </div>
        <div v-for="settings in productItemSizeFees" :key="settings.id">
          <single-oversize-item-fee :settings="settings" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { v4 as uuidv4 } from 'uuid'
import SingleOverSizeItemFee from './SingleOversizeItemFee'

export default {
  components: {
    'single-oversize-item-fee': SingleOverSizeItemFee,
  },
  data() {
    return {
      productItemSizeFees: [
        {
          id: uuidv4(),
          disabled: true,
          title: 'SMALL STANDARD SIZE',
          key: 'SMALL_STANDARD',
          shippingWeight: 'Up to 16 oz',
          longestSide: 'Up to 15 in',
          medianSide: 'Up to 12 in',
          shortestSide: 'Up to 0.75 in',
          lengthGirth: 'N/A',
        },
        {
          id: uuidv4(),
          disabled: false,
          title: 'LARGE STANDARD SIZE',
          key: 'LARGE_STANDARD',
          shippingWeight: 'Up to 20 lb',
          longestSide: 'Up to 18 in',
          medianSide: 'Up to 14 in',
          shortestSide: 'Up to 8 in',
          lengthGirth: 'N/A',
        },
        {
          id: uuidv4(),
          disabled: false,
          title: 'LARGE BULKY',
          key: 'LARGE_BULKY',
          shippingWeight: 'Up to 50 lb',
          longestSide: 'Up to 59 in',
          medianSide: 'Up to 33 in',
          shortestSide: 'Up to 33 in',
          lengthGirth: 'Up to 130 in',
        },
        {
          id: uuidv4(),
          disabled: false,
          key: 'EXTRA_LARGE_50_PLUS',
          title: 'EXTRA LARGE 0-50 LB',
          shippingWeight: 'Up to 50 lb',
          longestSide: 'Over 59 in',
          medianSide: 'Over 33 in',
          shortestSide: 'Over 33 in',
          lengthGirth: 'Over 130 in',
        },
        {
          id: uuidv4(),
          disabled: false,
          title: 'EXTRA LARGE 50-70 LB',
          key: 'EXTRA_LARGE_50',
          shippingWeight: '50+ to 70 lb',
          longestSide: 'Over 59 in',
          medianSide: 'Over 33 in',
          shortestSide: 'Over 33 in',
          lengthGirth: 'Over 130 in',
        },
        {
          id: uuidv4(),
          disabled: false,
          title: 'EXTRA LARGE 70-150 LB',
          key: 'EXTRA_LARGE_70_PLUS',
          shippingWeight: '70+ to 150 lb',
          longestSide: 'Over 59 in',
          medianSide: 'Over 33 in',
          shortestSide: 'Over 33 in',
          lengthGirth: 'Over 130 in',
        },
        {
          id: uuidv4(),
          disabled: false,
          title: 'EXTRA LARGE 150 PLUS',
          key: 'EXTRA_LARGE_150_PLUS',
          shippingWeight: 'Over 150 lb',
          longestSide: 'Over 59 in',
          medianSide: 'Over 33 in',
          shortestSide: 'Over 33 in',
          lengthGirth: 'Over 130 in',
        },
      ],
    }
  },
}
</script>

<style>

</style>
