<template>
  <div class="card-body py-1">
    <div class="row py-half border  item-fee-card rounded">
      <div class="col-12 text-warning mb-half">
        <strong>
          {{ settings.title }}
        </strong>
      </div>
      <div class="col-12">
        <div class="d-flex text-center">
          <div class="px-1">
            <span class="text-muted">
              Shipping Weight
            </span>
            <div>
              {{ settings.shippingWeight }}
            </div>
          </div>
          <div class="px-1">
            <span class="text-muted">
              Longest Side
            </span>
            <div>
              {{ settings.longestSide }}
            </div>
          </div>
          <div class="px-1">
            <span class="text-muted">
              Median Side
            </span>
            <div>
              {{ settings.medianSide }}
            </div>
          </div>
          <div class="px-1">
            <span class="text-muted">
              Shortest Side
            </span>
            <div>
              {{ settings.shortestSide }}
            </div>
          </div>
          <div class="px-1">
            <span class="text-muted">
              Length + girth
            </span>
            <div>
              {{ settings.lengthGirth }}
            </div>
          </div>
          <div class="flex-grow-1"></div>
          <div>
            <dx-util-number-box
              v-model="oversizeFee.value"
              :disabled="settings.disabled"
              format="$ #,##0.##"
              styling-mode="filled"
            />
          </div>
          <div class="pl-1">
            <dx-util-button
              icon="save"
              text="Save"
              :disabled="settings.disabled"
              :hint="`Save ${settings.title} Fee`"
              type="default"
              styling-mode="contained"
              :element-attr="btnElementAttr"
              @click="createOrUpdateOversizeFee"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import tenantService from '@/http/requests/tenant/tenan.settings'
import scopeTypeEnum from '@/enums/scopeTypeEnum'
import valueTypeEnum from '@/enums/valueTypeEnum'

export default {
  props: {
    settings: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      feeValue: 0,
      btnElementAttr: {
        class: 'btn-block',
      },
      oversizeFee: {
        id: null,
        key: this.settings.key,
        value: 0,
        tenantId: null,
        scopeType: scopeTypeEnum.TENANTSCOPEBACKEND.key,
        valueType: valueTypeEnum.BIGDECIMAL.key,
        version: null,
        referenceId: null,
      },
    }
  },
  mounted() {
    this.getOversizeFee()
  },
  methods: {
    getOversizeFee() {
      tenantService.fetchByKey(this.settings.key).then(result => {
        const data = { ...result.data }
        if (data.id) {
          this.oversizeFee = {
            id: data.id,
            key: data.key,
            value: parseFloat(data.value),
            tenantId: data.tenantId,
            scopeType: scopeTypeEnum.TENANTSCOPEBACKEND.key,
            valueType: data.valueType,
            version: data.version,
            referenceId: data.referenceId,
          }
        }
      })
    },
    createOrUpdateOversizeFee() {
      const oversizeFee = { ...this.oversizeFee }
      if (oversizeFee.id) {
        tenantService.update(oversizeFee).then(result => {
          const { id, version, tenantId } = result.data.body
          this.oversizeFee = {
            ...this.oversizeFee,
            id,
            version,
            tenantId,
          }
        })
      } else {
        tenantService
          .create(oversizeFee)
          .then(result => {
            const { id, version, tenantId } = result.data.body
            this.oversizeFee = {
              ...this.oversizeFee,
              id,
              version,
              tenantId,
            }
          })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
div.dark-layout .item-fee-card {
  background-color: rgba(30, 36, 49, 0.8);
  color: rgb(217, 219, 222);
}

div.light-layout .item-fee-card {
  background-color: rgba(196, 208, 224, 0.4);
  color: rgb(47, 68, 90);
}

div.bordered-layout .item-fee-card {
  background-color: rgba(196, 208, 224, 0.4);
  color: rgb(47, 68, 90);
}

</style>
